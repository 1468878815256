import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Page not found" />
    <article>
      <header>
        <div>
          <h1>Page not found</h1>
        </div>
      </header>
    </article>
  </Layout>
)

export default NotFoundPage
